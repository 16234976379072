<script>
import { required } from "vuelidate/lib/validators";
import validationMessages from '@/components/validations';
import Monitor from '@/services/Monitor';
import Swal from "sweetalert2";

export default {
  components: { validationMessages },
  props: ['socialLevelData'],
  data() {
    return {
      level: '',
      required_points: '',
      description: '',
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem('csrf_token'),
      tryingToEdit: false,
    };
  },

  validations: {
    level: { required },
    required_points: { required },
  },

  watch: {
    socialLevelData(newVal) {
      this.level = newVal.level;
      this.required_points = newVal.required_points;
      this.description = newVal.description;
    }
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.submitted = false;
      this.tryingToEdit = false;
    },

    async editSocialLevel() {
      this.tryingToEdit = true;
      this.submitted = true;
      this.tryingToSubmit = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      } else {
        let dealData = {
          level: this.level,
          required_points: this.required_points,
          description: this.description ? this.description : null,
        };

        try {
          const response = await Monitor.editSocialLevel(this.socialLevelData.level, dealData);
          const error = typeof response.error !== 'undefined' ? response.error : false;

          if (error !== '') {
            this.successmsg("Social Level successfully updated");
            this.closeModal();
          } else {
            this.failedmsg("Failed to update Social Level");
          }
        } catch (error) {
          this.error = error.response.data.error ? error.response.data.error : "";
          this.failedmsg(this.error);
        } finally {
          this.refreshData();
          this.tryingToSubmit = false;
          this.showLoader = false;
        }
      }
      this.tryingToSubmit = false;
    },


    refreshData() {
      this.$emit('onRefresh'); // Event to refresh data in parent component
    }
  }
};
</script>

<template>
  <b-modal id="edit__social_level" size="l" v-model="showModal" title="Edit Social Level" title-class="font-18">
    <form @submit.prevent="editSocialLevel()">
      <div class="row" v-if="socialLevelData">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <h4 class="card-title m-0">Social Level: {{ this.socialLevelData.level }}</h4>

            <div class="col-sm-12">
              <b-form-group label="Required Points" label-for="formrow-description-input" class="mb-3">
                <b-form-input v-model.trim="required_points" :class="{ 'is-invalid': submitted && $v.required_points.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Required Points'" :validationName="$v.required_points"></validationMessages>
              </b-form-group>

              <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                <b-form-input v-model.trim="description" :class="{ 'is-invalid': submitted && $v.description.$error }"></b-form-input>
              </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="editSocialLevel" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
